//分页查询巡检任务
const findPatrolTaskPageUrl = `/gateway/blade-system/patrolTask/findPatrolTaskPage`;
//根据ID获取巡检任务(移动端)
const getPatrolTaskDetailByIdUrl = `/gateway/blade-system/patrolTask/getPatrolTaskDetailById`;
//提交完成巡检
const inputPatrolTaskDetailUrl = `/gateway/blade-system/patrolTask/inputPatrolTaskDetail`;

//获取移动端登录用户类型
const getUserTypeUrl = `/gateway/blade-system/repairInfo/getUserType`;

export {
  findPatrolTaskPageUrl,
  inputPatrolTaskDetailUrl,
  getPatrolTaskDetailByIdUrl,
  getUserTypeUrl,
};
