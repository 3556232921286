<template>
  <div class="inspectionTasks">
    <div class="inspectionTasks-t">
      <div
        :class="['item', { active: requestData.status === item.value }]"
        v-for="(item, index) in navList"
        :key="index"
        @click="handelSelectNav(item, index)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="inspectionTasks-b">
      <van-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        :immediate-check="false"
        ref="list"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="handelClickRouter(item.id)"
        >
          <div class="item-t">
            <div class="item-tl">
              <div class="item-tll">巡检编号:</div>
              <div class="item-tlr">
                {{ item.serviceNumber ? item.serviceNumber : "/" }}
              </div>
            </div>
            <div class="item-tr">{{ oStatusMap[item.status] }}</div>
          </div>
          <div class="item-tt">
            {{ item.patrolName }}
          </div>
          <div class="item-b">
            <div class="item-bl">
              <div class="oItem">
                <div class="oItem-l">巡检内容:</div>
                <div class="oItem-r">{{ item.patrolContent }}</div>
              </div>
              <div class="oItem">
                <div class="oItem-l">巡检区域:</div>
                <div class="oItem-r" v-if="item.serviceZone">
                  <div
                    class="area"
                    v-for="(oItem, oIndex) in item.serviceZone1"
                    :key="oIndex"
                  >
                    <div class="area-l">{{ oItem }}</div>
                    <!-- <div class="area-r">
                      <div class="txt">路线</div>
                      <div class="img">
                        <img src="../img/nav.png" alt="" />
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="oItem">
                <div class="oItem-l">巡检日期:</div>
                <div class="oItem-r">{{ item.patrolEndTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { findPatrolTaskPageUrl, getPatrolTaskDetailByIdUrl, getUserTypeUrl } from "./api.js";
import { oStatusMap, oStatus } from "./map.js";
import { List } from "vant";

export default {
  name: "inspectionTasks",
  components: { [List.name]: List },
  props: {},
  data() {
    return {
      oStatusMap,
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
        status: "",
        tenantId: this.tenantId,
        userId: this.userId,
      },
      listData: [],
      oIndex: "",
      navList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "待完成",
          value: 0,
        },
        {
          label: "已完成",
          value: 1,
        },
      ],
      userTypesId: {}
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    mobile() {
      return this.$store.state.mobile;
    },
  },
  created() {
    this.$axios.get(getUserTypeUrl, { params: { mobile: this.mobile } }).then(res => {
      if (res && res.code === 200) {
        res.data = res.data || {};
        this.userTypesId = res.data;
      }
    }).finally(() => {
      this.onLoad();
    });
  },
  mounted() {},
  filters: {},
  methods: {
    //选择nav
    handelSelectNav(item, index) {
      this.requestData.status = item.value;
      this.requestData.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    //跳转详情
    handelClickRouter(id) {
      this.$router.push({
        name: "inspectionTasksDetail",
        query: {
          id,
        },
      });
    },
    onLoad() {
      this.findPatrolTaskPage();
    },
    findPatrolTaskPage() {
      this.requestData.userId = this.userTypesId[101];
      this.$axios
        .get(findPatrolTaskPageUrl, { params: this.requestData })
        .then((res) => {
          if (res.code === 200) {
            if (this.requestData.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (this.requestData.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.listData.forEach((ele) => {
              if (ele.serviceZone) {
                ele.serviceZone1 = ele.serviceZone.split(",");
              }
            });
            console.log(this.listData, "00");
            this.requestData.curPage++;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.inspectionTasks {
  min-height: 100vh;
  background: #f9f9f9;
  padding-top: 162px;
  box-sizing: border-box;
  .inspectionTasks-t {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
    left: 0;
    padding: 90px 0 28px;
    display: flex;
    justify-content: space-around;
    background: #fff;
    font-size: 28px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 40px;
    .active {
      position: relative;
      font-weight: 700;
      font-size: 32px;
      &::after {
        content: "";
        position: absolute;
        top: -10px;
        right: -28px;
        width: 28px;
        height: 26px;
        background: url("../img/icon.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .inspectionTasks-b {
    .item {
      margin-bottom: 26px;
      background: #fff;
      padding: 30px 40px 24px;
    }
    .item-tt {
      font-size: 32px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
    }
    .item-t {
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
      align-items: center;
      .item-tl {
        font-size: 28px;
        flex: 1;
        width: 400px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
        display: flex;
        .item-tlr {
          width: 400px;
          word-wrap: break-word;
        }
      }
      .item-tr {
        width: 100px;
      }
    }
    .item-b {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item-bl {
        flex: 1;
        .oItem {
          display: flex;
          align-items: center;
          font-size: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 56px;
          &:nth-child(2) {
            align-items: flex-start;
          }
          .oItem-l {
            width: 140px;
          }
          .oItem-r {
            flex: 1;
            color: rgba(0, 0, 0, 0.85);
            .area {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 5px;
            }
            .area-l {
              // width: 320px;
            }
            .area-r {
              display: flex;
              align-items: center;
              .txt {
                font-size: 28px;
                font-weight: 400;
                color: #2f7bff;
                line-height: 40px;
                margin-right: 10px;
              }
              .img {
                width: 58px;
                height: 58px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
